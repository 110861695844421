<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteReservationCancelRule"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content> </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {},

  created() {},

  methods: {
    deleteReservationCancelRule() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true
      }
    },
  },
}
</script>
